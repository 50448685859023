/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import 'styles/scss/mixins.scss';
@import 'styles/scss/variables.scss';
@import url('./assets/styles/toastr.css');

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// @font-face {
//   font-family: 'Roboto';
//   src: url('assets/fonts/Roboto-Regular.ttf');
// }

html,
body {
  box-sizing: border-box;
  // scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  // overflow: hidden;
}

ul,
ol,
dl {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.my-container {
  // max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    max-width: 1350px;
  }
}

.text-main {
  color: $color-main !important;
}

.border-main {
  border: 1px solid $color-main;
  border-radius: 8px;
}

.bg-main {
  background-color: $color-main;
  color: $color-white;
}

.loading-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 120px 0;
}

// ANIMATIONS 
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse-shrink:hover {
  -webkit-animation-name: pulse-shrink;
  animation-name: pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}